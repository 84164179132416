const routes: any[] = [
    {
        path: '/',
        component: () => import('../views/_modules/layout/common/layout-login.vue'),
        redirect: { name: 'login' },
        children: [
            {
                path: 'login',
                name: 'login',
                meta: {
                    title: '75619', // 页面title(这里指多语言的key)
                },
                component: () => import('../views/login/index.vue'),
            },
            {
                path: 'forget_password',
                name: 'forgetPassword',
                meta: {
                    title: '75620', // 页面title(这里指多语言的key)
                },
                component: () => import(/* webpackChunkName:"forget-password" */ '../views/forget-password/index.vue'),
            },
            {
                path: 'set_password',
                name: 'setPassword',
                meta: {
                    title: '75630', // 页面title(这里指多语言的key)
                },
                component: () => import('../views/set-password/index.vue'),
            },
        ],
    },
    {
        path: '/verify_token',
        name: 'verifyToken',
        meta: {
            title: '76175', // 页面title(这里指多语言的key)
        },
        component: () => import('../views/verify-token/index.vue'),
    },
];

export default routes;
