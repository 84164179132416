import { createStore } from 'vuex';
import antLocale from './modules/ant-locale';
import user from './modules/user';
import countryPhone from './modules/country-phone';
export default createStore({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        antLocale,
        user,
        countryPhone,
    },
    plugins: [
        // createPersistedState()
        // createPersistedState({
        //     storage:window.localStorage
        // })
    ],
});
