
import { useStore } from 'vuex'
// import { useRoute } from 'vue-router'
// import { useI18n } from 'vue-i18n'
import { computed, watch } from 'vue'
import dayjs from 'dayjs'

export default {
  setup() {
    const store = useStore()
    // const route = useRoute()
    // const { t } = useI18n({})

    // const getRouteTitle = () => {
    //   for (const item of route.matched.reverse()) {
    //     if (item.meta.title) {
    //       return t((item.meta.title || '') as string)
    //     }
    //   }
    // }

    // 多语言的时间格式
    watch(
      () => store.state.antLocale.locale,
      () => {
        const locale = store.state.antLocale.locale
        if (locale?.locale) {
          dayjs.locale(locale.locale)
        }
      },
      {
        immediate: true,
        deep: true
      }
    )

    return {
      locale: computed(() => store.state.antLocale.locale)
    }
  }
}
