import Axios, { AxiosResponse, AxiosError } from 'axios';
import router from '@/router/index';
import { Modal, message } from 'ant-design-vue';
import Cookie from 'js-cookie';

export const specialCode: { [key: string]: string | number } = {
    '1219P8402': '密码错误',
    '1219P8405': '密码错误',
    '1219P8301': '用户不存在',
    '1219P8408': '未绑定google',
    '1219P8401': 'lock账户',
}; // 特殊code码，当非success时，且命中对象内key值，将正常返回对象。

export const axiosInstance = Axios.create({
    timeout: 6000 * 10,
});

// 响应拦截
axiosInstance.interceptors.response.use(
    (response: AxiosResponse) => {
        const res = response.data;
        if (!res.success) {
            const error = res.error;
            const errorCode = error && error.code;

            // 需要登录
            if ([400, 4001, 11001].includes(+errorCode)) {
                _openLoginDialog(error.message);
                return Promise.reject(''); // error传空，不用小弹窗提示
            } else {
                return Promise.reject(error);
            }
        }

        return res.result;
    },
    (error: AxiosError) => {
        return Promise.reject(error);
    }
);

const get = (url: string, params = {}, isShowCommonErrorMessage = true) => {
    return new Promise((resolve: any, reject: any) => {
        axiosInstance
            .get(url, { params })
            .then((res: any) => {
                resolve(res);
            })
            .catch((err: any) => {
                if (specialCode[err && err?.code]) {
                    return reject(err);
                }
                if (err) {
                    const mes = (err && err.message) || '服务器开小差';
                    if (isShowCommonErrorMessage) {
                        message.error(mes);
                    }
                    reject(err);
                }
            });
    });
};
const get_export = (url: string, params = {}, isShowCommonErrorMessage = true) => {
    return new Promise((resolve: any, reject: any) => {
        Axios.create({
            timeout: 6000 * 10,
        })
            .get(url, { params, responseType: 'blob', headers: axiosInstance.defaults.headers.common })
            .then((res: any) => {
                resolve(res);
            })
            .catch((err: any) => {
                if (err) {
                    const mes = (err && err.message) || '服务器开小差';
                    if (isShowCommonErrorMessage) {
                        message.error(mes);
                    }
                    reject(err);
                }
            });
    });
};

const post = (url: string, params = {}, isShowCommonErrorMessage = true) => {
    return new Promise((resolve: any, reject: any) => {
        axiosInstance
            .post(url, params)
            .then((res: any) => {
                resolve(res);
            })
            .catch((err: any) => {
                if (specialCode[err && err?.code]) {
                    return reject(err);
                }
                if (err) {
                    const mes = (err && err.message) || '服务器开小差';
                    if (isShowCommonErrorMessage) {
                        message.error(mes);
                    }
                    reject(err);
                }
            });
    });
};

function _openLoginDialog(msg: string) {
    Modal.error({
        icon: 'minus-circle',
        // FIXME: 后端多语言有问题，暂时做个兼容
        content: msg === 'token invalid' ? 'Merchant not login' : msg,
        onOk() {
            Cookie.remove('isLogin');
            router.push({ name: 'login' });
        },
    });
}

export { get, post, get_export };
