export default {
    /***********************************************
/* 全局公共接口 */
    getPhoneCode: '/v1/openplatformsrv/common/get_phone_code', // 电话区号列表
    /***********************************************
     * 用户接口 */
    login: '/v1/openplatformsrv/user/login', // 登录
    logout: '/v1/openplatformsrv/user/logout', // 退出
    loginWithGoogle: '/v1/openplatformsrv/user/login_google', // google登录
    verifyToken: '/v1/openplatformsrv/password/verify_token', // 校验token
    resetPassword: '/v1/openplatformsrv/password/reset', // 激活、重置
    getUserInfo: '/v1/openplatformsrv/user/info', // 通过token获取用户信息

    // 二期
    changePassword: '/v1/openplatformsrv/password/change_password', // 修改密码
    findPassword: '/v1/openplatformsrv/password/find_password', // 找回密码

    //  导出功能
    summitExport: '/v1/openplatformsrv/proto/escrow/export', // 提交导出
    checkExport: '/v1/openplatformsrv/proto/download_task', // 查看导出状态
    downloadExport: '/v1/openplatformsrv/proto/download_task/download', //  导出下载

    /***********************************************
/* 首页接口 */

    getCategoryList: '/v1/openplatformsrv/proto/escrow/get_category_list', // 首页类别列表
    getEscrowList: '/v1/openplatformsrv/proto/escrow/list', // 首页履保列表
    getEscrowCount: '/v1/openplatformsrv/proto/escrow/total', // 首页履保统计
    getEscrowHistoryVersion: '/v1/openplatformsrv/proto/escrow/history_version', // 首页历史版本
};
