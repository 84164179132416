export default {
    namespaced: true,
    name: 'user',
    state: {
        user_guid: '',
        username: '',
        user_type: '',
        counter_name: '',
    },

    mutations: {
        updateUserInfo(state: any, payload: any) {
            state.user_guid = payload.user_guid || state.user_guid;
            state.username = payload.username || state.username;
            state.user_type = payload.user_type || state.user_type;
            state.counter_name = payload.counter_name || state.counter_name;
        },

        cleanUserInfo(state: any) {
            state.user_guid = '';
            state.username = '';
            state.user_type = '';
            state.counter_name = '';
        },
    },
    actions: {},
};
