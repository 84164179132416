export default {
    namespaced: true,
    name: 'antLocale',
    state: {
        locale: {},
    },

    mutations: {
        updateLocale(state: any, locale: any) {
            state.locale = locale;
        },
    },
};
