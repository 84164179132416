export default {
    namespaced: true,
    name: 'phoneCountry',
    state: {
        isLoadingPhoneCode: false,
        phoneCountryCode: [],
    },

    mutations: {
        setLoadingPhoneCode(state: any, isLoading: boolean) {
            state.isLoadingPhoneCode = isLoading;
        },
        setPhoneCountryCode(state: any, phoneCountry: any[]) {
            state.phoneCountryCode = phoneCountry || state.phoneCountryCode;
        },
    },
    actions: {},
};
