// import store from '@/store/index.ts';

const routes: any[] = [
    {
        path: '/',
        component: () => import(/* webpackChunkName:"desktop_layout" */ '../views/_modules/layout/desktop/layout.vue'),
        redirect: { name: 'Dashboard' },
        children: [
            {
                path: 'home',
                name: 'Dashboard',
                nav: true,
                meta: {
                    needAuth: true,
                    title: '75649', // 页面title(这里指多语言的key)
                },
                component: () => import(/* webpackChunkName:"desktop_home" */ '../views/home/index.vue'),
            },

            {
                path: 'settings',
                name: 'settings',
                nav: false,
                meta: {
                    needAuth: true,
                    title: '76214',
                },
                component: () => import(/* webpackChunkName:"desktop_settings" */ '../views/settings/index.vue'),
            },
        ],
    },
];

export default routes;
