import store from '@/store/index';
import type { App } from 'vue';
import { createI18n } from 'vue-i18n';
import type { I18n } from 'vue-i18n';
import msg from '@/locales/zh-TW.json';
import { axiosInstance } from './plugins/axios';
import { getAPILang } from './utils';

export let i18n: ReturnType<typeof createI18n> | any;

// 站点多语言与ant多语言文件名称映射
const antLocaleMapping: any = {
    en: 'en_GB',
    ja: 'ja_JP',
    ko: 'ko_KR',
    th: 'th_TH',
    'zh-CN': 'zh_CN',
    'zh-TW': 'zh_TW',
};

// 已加载的语言
const loadedLanguages: string[] = [];

function _setI18nLanguage(lang: string) {
    i18n.global.locale = lang;
    axiosInstance.defaults.headers.common['Accept-Language'] = getAPILang(lang);
    document.querySelector('html')?.setAttribute('lang', lang);
    return lang;
}

function loadLanguageAsync(lang: string) {
    // ant库多语言
    import(`ant-design-vue/es/locale/${antLocaleMapping[lang]}`).then((msg) => {
        store.commit('antLocale/updateLocale', msg.default);
    });

    if (!loadedLanguages.includes(lang)) {
        return import(`@/locales/${lang}.json`).then((msg) => {
            i18n.global.setLocaleMessage(lang, msg.default);
            loadedLanguages.push(lang);
            return _setI18nLanguage(lang);
        });
    }

    return Promise.resolve(_setI18nLanguage(lang));
}

const I18nInstall = (app: App) => {
    const options = {
        locale: 'zh-TW',
        fallbackLocale: 'zh-TW',
        messages: msg,
        globalInjection: true,
        sync: true,
        silentTranslationWarn: true, // true - warning off
        missingWarn: false,
        silentFallbackWarn: true,
    };
    i18n = createI18n(options) as I18n;
    app.use(i18n);
};

export { I18nInstall, loadLanguageAsync };
