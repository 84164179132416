const LANGUAGE_LIST: any = [
    {
        name: 'English',
        value: 'en',
        eventName: 'EN',
    },
    // {
    //     name: '简体中文',
    //     value: 'zh-CN',
    //     eventName: 'ZH-CN',
    // },
    {
        name: '繁體中文',
        value: 'zh-TW',
        eventName: 'ZH-TW',
    },
    // {
    //     name: '日本語',
    //     value: 'ja',
    //     eventName: 'JA',
    // },
    // {
    //     name: '한국어',
    //     value: 'ko',
    //     eventName: 'KO',
    // },
    // {
    //     name: 'ไทย',
    //     value: 'th',
    //     eventName: 'TH',
    // },
];

const LANGUAGE_LOCALE_MAPPING: any = {
    en: 'en_US',
    // 'zh-CN': 'zh_CN',
    'zh-TW': 'zh_TW',
    // ja: 'ja_JP',
    // ko: 'ko_KR',
    // th: 'th_TH',
};

export { LANGUAGE_LIST, LANGUAGE_LOCALE_MAPPING };
