const timesFormat = 'YYYY-MM-DD HH:mm:ss';

function getAPILang(lang: string) {
    if (lang.includes('en')) {
        return 'en_US';
    }
    if (!lang.includes('-')) {
        // lang格式不对...
        return 'en_US';
    }
    return lang.replace('-', '_');
}

export { timesFormat, getAPILang };
