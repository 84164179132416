import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import Antd from 'ant-design-vue';
import { I18nInstall } from './i18n';
// import 'dayjs/locale/zh-cn';
import 'dayjs/locale/zh-tw';
import 'ant-design-vue/dist/antd.css'; //Antd 样式
import './common/css/global.less'; // 自定义全局样式

const bootstrap = () => {
    const app = createApp(App);
    app.use(store);
    I18nInstall(app);
    app.use(router);
    app.use(Antd);

    app.mount('#app');
};

bootstrap();

// 处理SVG
const svgFiles = require.context('./assets/imgs/svg-icon', false, /.svg$/);
svgFiles.keys().map(svgFiles);
